import React, {Fragment, useState} from 'react'
import useCollapse from 'react-collapsed';
import EventTimeFrame from './eventtimeframe'
import EventDetail from './eventdetail'
import EventOnline from './eventonline.jsx'
import Heading from './heading'
import GrizHubLink from "./grizhublink";
import EventPermaLink from "../eventpermalink";

function Event(props) {
    const [isExpanded, setExpanded] = useState(false);
    const {getCollapseProps, getToggleProps} = useCollapse({ isExpanded});

    const eventProps = {
        className: 'm-event',
    }

    const toggleProps = getToggleProps({
        onClick: () => setExpanded((prevExpanded) => !prevExpanded),
        className: 'griz-hub-events__description-button',
        'aria-labelledby': 'm-event__heading-'+props.data.id

//        onKeyDown: (e) => { if (e.keyCode == 13) setExpanded((prevExpanded)=> !prevExpanded) },
    })

    const detailsButtonText = () => {
        if(isExpanded) return 'Hide Description'
        return 'Show Description'
    }

    return (
        <li aria-labelledby={'m-event__heading-' + props.data.id} className={'griz-hub-events__event'}>
            <img className="griz-hub-events__event-image" src={props.data.imageUrl + '?preset=sm-w'} alt=""/>
            <div className="griz-hub-events__event-text">
                <Heading modifier={1} className="griz-hub-events__event-heading"
                         id={'m-event__heading-' + props.data.id}>
                    {props.data.name}
                </Heading>
                <date className="griz-hub-events__event-date-time">
                    <EventTimeFrame startsOn={props.data.startsOn} endsOn={props.data.endsOn}/>
                    <EventOnline addressName={props.data.address.name}/>
                </date>
                <div>
                    <button {...toggleProps} >
                        {detailsButtonText()}
                    </button>
                </div>
            </div>

            <div className="griz-hub-events__event-link">
                <GrizHubLink {...props.data} />
            </div>

            <div {...getCollapseProps()}>
                <EventDetail {...props.data} />
            </div>

        </li>
    )
}

export default Event
